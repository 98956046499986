<template>
<div>
    <el-tabs type="border-card" class="m-1" style="height:90vh; overflow: scroll;" v-if="menu.includes('communication')">
        <el-tab-pane label="SMS">
            <el-tabs v-model="activeName">
               <el-tab-pane label="Clients" name="first">
                    <PageClientSMS v-bind:messageCount="messageCount" />
               </el-tab-pane>
               <el-tab-pane label="Interne" name="second">
                    <PageInterneSMS  v-bind:messageCount="messageCount" />
               </el-tab-pane>
            </el-tabs>
        </el-tab-pane>
        <el-tab-pane label="Emails" style="overflow: hidden;">
            <v-row>
            </v-row>
        </el-tab-pane>
    </el-tabs>
    <el-empty description="Vous n'avez pas accès !" v-else></el-empty>

</div>

</template>

<script>

    import PageInterneSMS from './Communication/Sms/PageInterneSMS.vue';
    import PageClientSMS from './Communication/Sms/PageClientSMS.vue';
    import NProgress from 'nprogress';
    import 'nprogress/nprogress.css';


    export default {

        components:{
            PageInterneSMS,
            PageClientSMS
        },

        data() {
            return {
                activeName: 'first',
                messageCount: 0,
                menu : (JSON.parse(localStorage.getItem('menu'))).menu.menu,
            };  
        }, 

        created(){
            NProgress.done();
        }

   }
</script>

<style>
.b-0 {
    border-radius:0px;
}
</style>